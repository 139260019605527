const info = {
    CN: {
        //中文翻译
        banner: {
            title: "公司动态",
            text: "",
        },
        sigrealNews: [
            {
                date: "2025年1月",
                title: "宏瓴科技入选“毕马威中国 KPMG Fintech 50”",
                text: "凭借近六年来在金融、交通运输领域的持续深耕，尤其是大数据和智能风控细分领域的丰硕成果，宏瓴科技入选“2024 KPMG Fintech 50 ”，这一荣誉充分彰显出业内权威机构对宏瓴科技在金融与交-通运输领域以及创新性科研能力、智能风控解决方案能力的高度认可，也是对宏瓴科技未来发展潜力和行业引领地位的认可。",
                src: "dynamic-24.png",
            },
            {
                date: "2024年12月",
                title: "宏瓴科技CEO入选 “2024年度杰出青年创新领袖”",
                text: "宏瓴科技创始人兼CEO入选2024第八届全球青年创新大会 “年度杰出青年创新领袖”。这是CEO继2023年度中国保险科技十年百人、2022年度创业之星、2020年度中关村U30、2022年度36氪“保持奇迹”「X·36Under36」年度S级青年创业者之后获得的又一荣誉称号，也是对宏瓴科技在新经济领域下杰出贡献的有力见证。",
                src: "dynamic-23.png",
            },
            {
                date: "2024年12月",
                title: "宏瓴科技入选“2024年度最具成长力创新公司”",
                text: "宏瓴科技荣膺“2024 年度最具成长力创新公司”。宏瓴科技凭借其自主研发的全球首款端到端的AI风险量化评估模型在风险管理领域取得了领先地位。作为人工智能风险管理领域的先驱，宏瓴科技通过持续的技术创新以及研究，与多家企业和研究机构建立了深度商业模式融合。",
                src: "dynamic-22.png",
            },
            {
                date: "2024年1月",
                title: "2023年特色产业集群赋能典型案例-多模态数据 AI 助力交通出行领域风险减量",
                text: "1月23日，宏瓴科技“多模态数据 AI 助力交通出行领域风险减量”入选典型案例。“多模态数据析AI”项目专注于AI驱动的风险评估与管理。利用大模型技术和多模态数据，提供基于用户行为的风险评估和定价，通过主动介入企业日常风险管理，降低企业风险成本。针对企业用户衍生出的新的风险管理诉求，依托新技术，结合产业升级，不断拓展风险减量服务边界。",
                src: "dynamic-21.png",
            },
            {
                date: "2024年1月",
                title: "宏瓴科技获战略合作伙伴小鹏汽车颁发“AI风控创新奖”",
                text: "1月12日，宏瓴科技获“AI风控创新奖”。这是继2023年4月小鹏汽车颁发“AI科技创新贡献奖”后对宏瓴科技以AI助力车险智能化专业能力的再次认可。2023年，宏瓴科技通过自主研发的AI模型实时计算识别为小鹏汽车车主提供理赔服务。未来，还将通过差异化风险定价，帮助小鹏汽车打造数智化产品运营闭环和服务运营闭环，实现客户精细化运营管理，满足产品全生命周期的用户全触点体验。",
                src: "dynamic-20.png",
            },
            {
                date: "2024年1月",
                title: "宏瓴科技登榜毕马威中国“AI与大数据”双50榜单",
                text: "1月9日，上榜毕马威中国2023“AI与大数据”双50榜单。宏瓴科技利用大数据、人工智能等技术手段，通过主动介入企业日常风险管理，降低企业风险成本。依托新技术的应用，针对企业用户衍生出的新的风险管理诉求，通过与产业升级相结合从而不断拓展风险减量服务边界。",
                src: "dynamic-19.png",
            },
            {
                date: "2023年12月",
                title: "宏瓴科技“全栈式风险评估量化平台”荣膺“保险+人工智能”经典案例",
                text: "“全栈式风险评估量化平台”项目成功入选保观“寻找未来：保险+人工智能实践案例征集”。“全栈式风险评估量化平台”专注于AI驱动的保险风险评估与管理。利用先进的大模型技术和多模态数据，提供基于用户行为的风险评估和定价，辅助保险公司在承保、运营和理赔各环节实现智能化风控。同时通过实时行为分析、风险案件理赔预警等功能，有效减少企业运营成本，提高保险服务的效率和精准度。",
                src: "dynamic-18.png",
            },
            {
                date: "2023年12月",
                title: "宏瓴科技CEO获选“中国保险科技十年百人”",
                text: "12月28号，2023 中国保险科技十年百人榜单发布，宏瓴科技创始人兼CEO入选“中国保险科技十年百人评选”榜单。作为启动并引领了新的保险科技项目的“创新者”，宏瓴科技创始人兼CEO有着丰富的精算建模、系统平台设计、AI风险管理等经验，曾荣获2022年度创业之星、2020年度中关村U30、2022年度36氪“保持奇迹”「X·36Under36」年度S级青年创业者等多项荣誉。",
                src: "dynamic-17.png",
            },
            {
                date: "2023年11月",
                title: "宏瓴科技CEO受邀出席波士顿大学亚洲校友峰会",
                text: "11月3日至5日，波士顿大学在深圳举办2023年度波士顿大学亚洲校友峰会。4日，宏瓴科技创始人兼CEO作为创业者代表受邀进行企业介绍及创业分享。宏瓴科技专注于AI foundation模型技术研究，通过精细化运营实现风险减量，降低企业风险管理成本，始终致力于将AI技术深度应用服务于合作伙伴，“Transform Risk Into Return（将风险转化为回报）”。",
                src: "dynamic-16.png",
            },
            {
                date: "2023年5月",
                title: "人民日报、中国工业新闻网等主流媒体对小鹏汽车战略合作伙伴宏瓴科技进行报道",
                text: "人民日报、中国工业新闻网、凤凰网、网易新闻、新浪新闻发文“宏瓴科技牵手造车新势力 智能车险时代即将来临”、““大数据＋AI”赋能保险服务，智能车险时代即将来临”，采访为小鹏汽车与８家保险公司提供智能车险技术支持的宏瓴科技。宏瓴科技作为人工智能技术领域的先行者，将针对市场和用户的需求，与保险公司、汽车主机厂共同构建智能化汽车后市场服务体系，加快推动智能保险时代的到来。",
                src: "dynamic-15.png",
            },
            {
                date: "2023年4月",
                title: "宏瓴科技获战略合作伙伴小鹏汽车颁发“AI科技创新贡献奖”",
                text: "宏瓴科技与小鹏汽车在创新保险产品研发、智能理赔、风险管控等多方面展开合作。宏瓴科技在物联网大数据、车险个性化定价、理赔风控等场景拥有强大的技术优势，以物联网创新保险风控产品赋能汽车后市场保险服务的进步与提升。“AI科技创新贡献奖”称号的获得，也是合作伙伴对宏瓴科技以AI助力车险智能化专业能力的认可。",
                src: "dynamic-14.png",
            },
            {
                date: "2023年3月",
                title: "宏瓴科技CEO入选“2022年度创业之星”",
                text: "宏瓴科技CEO入选“2022年度创业之星”，这是CEO继“‘保持奇迹’「X·36Under36」年度S级青年创业者”之后获得的又一荣誉称号，也是对宏瓴科技在技术领域深耕细作、以技术迭代产品、以产品驱动商业的运作模式的认可。",
                src: "dynamic-13.png",
            },
            {
                date: "2022年10月",
                title: "宏瓴科技与太平洋财产保险成立车联网智能联合实验室",
                text: "宏瓴科技与太平洋财产保险股份有限公司“车联网智能联合实验室”在北京成立。双方协同创新，整合技术、人员、产品和系统资源，共同推进创新型数智化保险建设领域的创新应用。",
                src: "dynamic-lab.png",
            },
            {
                date: "2022年6月",
                title: "宏瓴科技CEO获36氪“保持奇迹”「X·36Under36」年度S级青年创业者称号",
                text: "宏瓴科技CEO荣获36氪“保持奇迹”「X·36Under36」年度S级青年创业者称号，这是宏瓴科技CEO继“创青春-中关村U30”之后获得又一荣誉称号，也是对宏瓴科技商业生态和社会效益的一种肯定。",
                src: "dynamic-12.png",
            },
            {
                date: "2022年2月",
                title: "中央电视台走进宏瓴科技做“人才就业”专题采访",
                text: "宏瓴科技计划招聘50到100个与人工智能、大数据等相关的岗位人员。为吸引人才，宏瓴科技开出业内极有竞争力的薪资待遇。此次采访于CCTV2《经济信息联播》、《天下财经》、《正点财经》等多档栏目播出。",
                src: "dynamic-11.png",
            },
            {
                date: "2022年1月",
                title: "宏瓴科技与华农保险成立车联网数字化运营联合实验室",
                text: "车联网数字化运营联合实验室的成立，是在UBI、车联网、保险与科技互相融合新态势下的一次重要合作，也是基于人工智能打造保险企业数字化产品落地样板间的一次强强联合。",
                src: "dynamic-10.png",
            },
            {
                date: "2022年1月",
                title: "宏瓴科技获选北京市2022年度第一批认定“专精特新”科技型企业",
                text: "本次入选是宏瓴科技向“专、精、特、新”稳步迈进的里程碑。未来，宏瓴科技将持续投入人工智能研发，构建适用于保险科技、大出行等业务场景的机器学习算法。",
                src: "dynamic-9.png",
            },
            {
                date: "2021年12月",
                title: "宏瓴科技荣登“2021毕马威中国领先金融科技50企业”榜单",
                text: "宏瓴科技的入选，显示了自身在运用人工智能、深度学习、物联网、大数据、云计算等技术赋能保险行业转型升级、降本增效、服务实体经济发展等方面得到了业界的广泛认可。",
                src: "dynamic-8.png",
            },
            {
                date: "2021年9月",
                title: "宏瓴科技受邀出席再保险（中国）市场研讨会",
                text: "宏瓴科技CEO以专家身份受邀分享UBI保险及新能源汽车后市场发展相关内容。此次研讨会有人保、太保、平安、国寿财、中华联合保险等50多家保险公司再保部和业务条线等相关部门领导参加。",
                src: "dynamic-7.png",
            },
            {
                date: "2021年9月",
                title: "新能源汽车智能保险开启新时代：宏瓴科技与高合汽车达成战略合作",
                text: "这是国内保险科技公司和汽车品牌在保险创新领域的一次里程碑式合作，此次合作必将开启新能源汽车智能保险创新的新时代，为全行业打造保险科技公司与豪华智能纯电品牌合作共创的示范样板。",
                src: "dynamic-6.png",
            },
            {
                date: "2021年7月",
                title: "宏瓴科技荣获“2021年度保险科技创新优秀奖”",
                text: "这是宏瓴科技继InsurStar保险科技新锐奖Top25（暨专注于UBI的保险科技上榜企业）后获得的又一行业知名奖项。",
                src: "dynamic-5.png",
            },
            {
                date: "2020年12月",
                title: "宏瓴科技荣获「2020 InsurStar保险科技新锐奖」",
                text: "宏瓴科技成为“2020 InsurStar保险科技新锐奖TOP25”中上榜的专注于UBI的保险科技企业。",
                src: "dynamic-4.png",
            },
            {
                date: "2020年11月",
                title: "宏瓴科技在团中央举办的青年创新创业大赛获银奖",
                text: "宏瓴科技以“基于人工智能、物联网大数据在汽车后市场的应用”项目获共青团中央、江西省人民政府主办的第七届“创青春”中国青年创新创业大赛（互联网组）全国赛银奖。",
                src: "dynamic-3.png",
            },
            {
                date: "2020年9月",
                title: "2020年中关村U30年度优胜者",
                text: "宏瓴科技CEO入选中关村、共青团中央“创青春-U30”2020年度优胜者。获奖照片曾于东单地铁站展示。",
                src: "dynamic-2.png",
            },
            {
                date: "2020年8月",
                title: "宏瓴科技在Plug and Play China 中国创新生态大会获得最具潜力奖",
                text: "本次活动宏瓴科技在移动出行和保险科技两个论坛中分别进行了路演展示。基于过去一年以及疫情期间的努力，宏瓴科技获得主办方Plug and Play China颁发的“最具潜力奖”。",
                src: "dynamic-1.png",
            },
        ],
    },
    EN: {
        //英文翻译
        banner: {
            title: "SiGREAL News",
            text: "",
        },
        sigrealNews: [
            {
                date: "Jan. 2025",
                title: `Hongling Technology Selected for "KPMG China Fintech 50"`,
                text: "",
                src: "dynamic-24.png",
            },
            {
                date: "Dec. 2024",
                title: `The CEO of SiGREAL Tech has been selected for the "2024 Outstanding Young Innovative Leader" award.`,
                text: "",
                src: "dynamic-23.png",
            },
            {
                date: "Dec. 2024",
                title: `SiGREAL Tech has been selected as one of the "Most Promising Innovative Companies of 2024."`,
                text: "",
                src: "dynamic-22.png",
            },
            {
                date: "Jan. 2024",
                title: `Typical case of enabling characteristic industry clusters 2023-Multimodal Data AI Empowers Risk Reduction in the Field of Transportation`,
                text: "",
                src: "dynamic-21.png",
            },
            {
                date: "Jan. 2024",
                title: `SiGReal Tech awarded "AI Risk Control Innovation Award"`,
                text: "",
                src: "dynamic-20.png",
            },
            {
                date: "Jan. 2024",
                title: `SiGReal Tech listed on the KPMG China-Fintech-50-2023 "AI and Big Data" List`,
                text: "",
                src: "dynamic-19.png",
            },
            {
                date: "Dec. 2023",
                title: `SiGReal Tech's "Full-stack Risk Assessment Quantitative Platform" selected for the "Insurance + AI" classic case`,
                text: "",
                src: "dynamic-18.png",
            },
            {
                date: "Dec. 2023",
                title: `SiGReal Tech CEO  selected for the  "China Insurance Technology Ten-Year Hundred People"`,
                text: "",
                src: "dynamic-17.png",
            },
            {
                date: "Nov. 2023",
                title: `The CEO of SiGReal Tech was invited to attend the Boston University Asian Alumni Forum.`,
                text: "",
                src: "dynamic-16.png",
            },
            {
                date: "May. 2023",
                title: `Mainstream media outlets such as People's Daily and China Industry News have covered the intelligent claims technology supplier, SiGReal Tech, for Xiaopeng Motors.`,
                text: "",
                src: "dynamic-15.png",
            },
            {
                date: "Apr. 2023",
                title: 'SiGReal Tech received the "AI Technology Innovation Contribution Award" from XPeng Motors.',
                text: "",
                src: "dynamic-14.png",
            },
            {
                date: "Mar. 2023",
                title: 'The CEO of SiGReal Tech was selected as the "2022 Entrepreneurial Star".',
                text: "",
                src: "dynamic-13.png",
            },
            {
                date: "Oct. 2022",
                title: "SiGReal Tech and China Pacific Property Insurance have joined hands to establish the Intelligent Joint Laboratory of Internet of Vehicles",
                text: "",
                src: "dynamic-lab.png",
            },
            {
                date: "Jun. 2022",
                title: "The CEO of SiGReal Tech was awarded as S class Young Entrepreneur of 36Kr “Keeping the Miracle” 「X·36Under36」.",
                text: "",
                src: "dynamic-12.png",
            },
            {
                date: "Feb. 2022",
                title: "The CEO of SiGReal Tech introduced to CCTV reporters that we planned to recruit 50 to 100 talents in the field of AI, big data, and some other positions.",
                text: "",
                src: "dynamic-11.png",
            },
            {
                date: "Jan. 2022",
                title: 'SiGReal Tech set up a "Joint Lab of IoV Digital Operation" to cooperate with CHIC (China Huanong Property & Casualty Insurance Co., Ltd.).',
                text: "",
                src: "dynamic-10.png",
            },
            {
                date: "Jan. 2022",
                title: 'SiGReal Tech was awarded the first batch of "Specialized, Refinement, Differentiation and Innovative" technology enterprises in 2022 in Beijing.',
                text: "",
                src: "dynamic-9.png",
            },
            {
                date: "Dec. 2021",
                title: `SiGReal Tech was listed among "2021 KPMG's Top 50 Financial and High-Tech Enterprises in China".`,
                text: "",
                src: "dynamic-8.png",
            },
            {
                date: "Sep. 2021",
                title: "SiGReal Tech was invited to attend Taiping Reinsurance (China) Market Seminar.",
                text: "",
                src: "dynamic-7.png",
            },
            {
                date: "Sep. 2021",
                title: "SiGReal Tech reached strategic cooperation with HiPhi: An intelligent insurance age is coming.",
                text: "",
                src: "dynamic-6.png",
            },
            {
                date: "July. 2021",
                title: 'SiGReal Tech was awarded in the "2021 Insurance Innovation Competition".',
                text: "",
                src: "dynamic-5.png",
            },
            {
                date: "Dec. 2020",
                title: 'SiGReal Tech was awarded the "Top 25 New Prominent InsurTech Award of 2020".',
                text: "",
                src: "dynamic-4.png",
            },
            {
                date: "Nov. 2020",
                title: 'SiGReal Tech won the silver medal of the "Youth Innovation and Entrepreneurship Competition".',
                text: "",
                src: "dynamic-3.png",
            },
            {
                date: "Sep. 2020",
                title: 'SiGReal Tech was the winner of "2020 Zhongguancun U30".',
                text: "",
                src: "dynamic-2.png",
            },
            {
                date: "Aug. 2020",
                title: 'SiGReal Tech won the most potential award in the "Plug and Play China Innovation Ecology Conference".',
                text: "",
                src: "dynamic-1.png",
            },
        ],
    },
};
export default info; //需要暴露出去
