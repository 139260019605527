<template>
    <div class="wrap">
        <van-nav-bar title="职位详情" fixed left-arrow @click-left="onClickLeft" />
        <div class="section">
            <div class="post_drawer" v-if="!checkedPost.name">
                <div class="post_title">瓴先人才计划</div>
                <p class="post_small_title">瓴先人才计划</p>
                <ul class="post_ul">
                    <li>面向全球TOP 50院校招募科技人才，培养及储备能引领未来人工智能与机器学习的业内顶尖专家。</li>
                </ul>
                <p class="post_small_title post_small_title1">你将会做什么：</p>
                <ul class="post_ul">
                    <li>进入核心团队，主导或参与核心项目，解决面向保险科技、大出行、新能源等极具挑战性的行业级场景技术问题。</li>
                </ul>
                <p class="post_small_title post_small_title1">你的工作伙伴</p>
                <ul class="post_ul">
                    <li>来自清华大学、中科院、波士顿大学、纽约大学等国际知名院校；</li>
                    <li>曾就职于世界500强企业及顶级科技公司核心岗位；</li>
                    <li>拥有车联网大数据分析、人工智能、深度机器学习、保险精算等资深专业背景。</li>
                </ul>
                <p class="post_small_title post_small_title1">申请要求</p>
                <p class="post_small_title post_small_title1">如果你全球TOP 50院校在读或毕业两年内，只要符合以下任意一类即可申请：</p>
                <ul class="post_ul">
                    <li>1、计算机/数学/物理/统计等相关专业，成绩Top 5%；</li>
                    <li>2、在国际顶级会议或期刊上发表过机器学习相关论文；</li>
                    <li>3、参加知名数据挖掘竞赛如KDDCup等并取得优异名次；</li>
                    <li>4、参加知名编程比赛如ACM/ICPC并取得优异名次；</li>
                    <li>5、Top创业公司或世界知名大公司实习经历。</li>
                </ul>
                <p class="post_small_title post_small_title1">关于职位：无具体岗位JD，只要你足够优秀，可量身定制</p>
                <ul class="post_ul">
                    <li>诚邀你加入“瓴先人才计划”，与我们共同成长；</li>
                    <li>希望你保持好奇心，从深度/广度不断拓宽自己的能力边界；</li>
                    <li>通过人工智能技术推动时代发展。</li>
                </ul>
            </div>
            <div class="post_drawer" v-else>
                <div class="post_title">{{ checkedPost.name }}</div>
                <p class="post_small_title">岗位职责</p>
                <ul class="post_ul">
                    <li v-for="(item, index) in checkedPost.duty" :key="index">{{ item }}</li>
                </ul>
                <p class="post_small_title">任职要求</p>
                <ul class="post_ul">
                    <li v-for="(item, index) in checkedPost.request" :key="index" v-html="item"></li>
                </ul>
            </div>
        </div>
        <div class="footer">
            <div class="submit_btn" @click="handleApplyPost">申请该职位</div>
            <!-- <a  class="call" href="tel:010-87856177">
			<div>
				<img src="../../public/images/join/dianhua.png" >
			</div>
			<span>拨打电话</span>
		</a> -->
        </div>
        <contact-modal :dialogFormVisible="dialogFormVisible" :jobApplication="jobApplication" @closeModal="closeModal"></contact-modal>
    </div>
</template>

<script>
// @ is an alias to /src
import ContactModal from '@/components/ContactModal.vue';
import { getScrollParent } from '@/assets/js/common.js';
export default {
    name: 'PostDetail',
    components: {
        ContactModal
    },
    data() {
        return {
            dialogFormVisible: false,
            checkedPost: {
                name: '',
                duty: ['', '', ''],
                request: ['', '', '']
            },
            jobApplication: ''
        };
    },
    methods: {
        closeModal() {
            this.dialogFormVisible = false;
        },
        onClickLeft() {
            this.$router.go(-1);
        },
        //点击申请该职位按钮
        handleApplyPost() {
            this.dialogFormVisible = true;
        },

        handlePost(postName) {
            this.checkedPost = {
                name: '',
                duty: ['', '', ''],
                request: ['', '', '']
            };
            if (postName != '瓴先人才计划') {
                this.$t('Public.PostDetail.postDetails').forEach((val, key) => {
                    if (postName == val.name) {
                        this.checkedPost = val;
                    }
                });
            }
        }
    },
    mounted() {
        const element = getScrollParent(this.$el);
        element.scrollTop = 0;
        this.handlePost(this.$route.query.postName);
        this.jobApplication = this.$route.query.postName;
    }
};
</script>
<style scoped>
@import '../assets/css/public.css';

.post_title {
    font-size: 0.34rem;
    font-weight: 600;
}
.post_small_title {
    margin: 0.5rem 0 0.3rem;
    font-size: 0.3rem;
    font-weight: 600;
}
.post_ul li {
    text-align: justify;
    line-height: 2;
}
.post_ul1 {
    margin-top: 0.3rem;
}
.section {
    padding: 1.2rem 0 1.4rem;
}
.post_drawer {
    padding: 0 0.3rem;
}
/deep/.van-nav-bar .van-icon {
    color: #000;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 0.94rem;
    border-top: 1px solid #f4f3f3;
    display: flex;
}
.submit_btn {
    /* width: 4.96rem; */
    width: 100%;
    height: 0.94rem;
    line-height: 0.94rem;
    background: #05a3e4;
    color: #fff;
    text-align: center;
}
.call {
    width: 2.54rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}
.call div {
    width: 0.44rem;
    margin-right: 0.15rem;
}
</style>