const info = {
    CN: {
        //中文翻译
        banner: {
            slogan: "SiGREAL AI, Be Pathfinder Together",
            title1: "构建世界级",
            title2: "交通运输行业大模型",
            title3: "Build World-Class Transportation AI Foundation Model",
            title4: "",
            // title:"人工智能赋能者",
            // content:"释放人工智能价值，赋能保险公司、汽车主机厂等创新保障业务场景，助力产业升级"
        },
        aboutUs: {
            title: "公司简介",
            content1:
                "宏瓴科技(SiGREAL Tech)专注于为交通运输行业构建大模型、提供风险减量服务。公司基于AI领域最前沿技术，多模态大模型，强化学习及图神经网络等，自主研发出全栈式风险评估量化平台。目前为行业内多家TOP级客户提供专业服务。",
            content2:
                "核心团队成员来自于清华大学、中科院、波士顿大学、加州大学、苏黎世联邦理工等国内外顶尖院校，曾就职于硅谷知名高科技公司、世界500强集团等核心岗位，长期在国际顶级人工智能会议上发表学术成果，在机器学习、车联网大数据等领域拥有资深的基础研究背景。",
            content3:
                "公司为国家级高新技术企业、北京市专精特新中小企业、中关村高新技术企业、中关村金种子企业及科技部认证的科技型企业，拥有核心专利及软著百余项。",
        },
        ourCulture: {
            title: "企业文化",
            cultureTitle: [
                "成就客户",
                "务实落地",
                "正直担当",
                "延迟满足感",
                "强执行力",
                "乐观进取",
            ],
        },
        service: {
            title: "我们的服务",
            list: [
                {
                    title: "AI+主机厂",
                    txt1: "基于多模态大模型的车联网服务",
                },
                {
                    title: "AI+出行/商用车平台",
                    txt1: "营运车队风险管控服务",
                },
                {
                    title: "AI+物流配送平台",
                    txt1: "新兴行业安全保障服务",
                },
            ],
        },
        quote: {
            title: "“AI has the potential to be one of the most important and beneficial technologies ever invented.”",
            sign1: "DEMIS HASSABIS",
            sign2: "CO-FOUNDER AND CEO, GOOGLE DEEPMIND",
        },
    },
    EN: {
        //英文翻译
        banner: {
            slogan: "SiGREAL AI, Be Pathfinder Together",
            title1: "Build World-Class Transportation",
            title2: "AI Foundation Model",
            title3: "",
            title4: "",
            // title:"Artificial Intelligence Enablers",
            // content:""
        },
        aboutUs: {
            title: "About Us",
            content1:
                "SiGREALTech specializes in developing large-scale models and providing risk mitigation services for the transportation industry. Leveraging cutting-edge AI technologies, including multimodal large models, reinforcement Learning, and Graph Neural Networks (GNN) etc, the company has independently developed a full-stack risk assessment and quantification platform. Currently, SiGREALTech delivers professional services to several top-tier clients within the industry.",
            content2:
                "The core team members of SiGREALTech hail from prestigious institutions such as Tsinghua University, the Chinese Academy of Sciences, Boston University, the University of California, and ETH Zurich. They have previously held key positions at renowned high-tech companies in Silicon Valley and Fortune 500 corporations. With extensive experience in publishing academic work at top international AI conferences, the team has a deep foundational background in areas such as machine learning and big data for connected vehicles.",
            content3:
                'The company is recognized as a National High-Tech Enterprise, a "Specialized, Refined, Special, and New" SME in Beijing, a Zhongguancun High-Tech Enterprise, a Zhongguancun Golden Seed Enterprise, and a technology-based enterprise certified by the Ministry of Science and Technology. SiGREALTech holds over a hundred core patents and software copyrights.',
        },
        ourCulture: {
            title: "Our Culture",
            cultureTitle: [
                "Pursue Customer Success",
                "Down-to-Earth",
                "Integrity",
                "Delayed Gratification",
                "Execution",
                "Proactivity",
            ],
            cultureIntro: ["", "", "", "", "", ""],
        },
        service: {
            title: "Services",
            list: [
                {
                    title: "Al+Automotive OEM",
                    txt1: "Multimodal Large Model-Based IoV Services",
                },
                {
                    title: "Al+Mobility/CommercialVehicle Platforms",
                    txt1: "Fleet Risk Control",
                },
                {
                    title: "Al+Logistics/DeliveryPlatforms",
                    txt1: "Logistics/Delivery Industry Safety and Security Services",
                },
            ],
        },
        quote: {
            title: "“AI has the potential to be one of the most important and beneficial technologies ever invented.”",
            sign1: "DEMIS HASSABIS",
            sign2: "CO-FOUNDER AND CEO, GOOGLE DEEPMIND",
        },
    },
};
export default info; //需要暴露出去
