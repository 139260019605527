<template>
    <div class="wrap">
        <my-header></my-header>
        <div class="section_en" v-if="$i18n.locale == 'EN'">
            <div class="banner_en">
                <p>SiGREAL Talents Plan</p>
                <!-- <div>Let's do the right things when it's hard!</div> -->
            </div>
            <div class="element_en">
                <div class="element element_1"><img src="../../public/images/join/en/element_1.png" /></div>
                <div class="element element_2"><img src="../../public/images/join/en/element_2.png" /></div>
                <div class="element element_3"><img src="../../public/images/join/en/element_3.png" /></div>
                <div class="element element_4"><img src="../../public/images/join/en/element_4.png" /></div>
            </div>
            <div class="content_en">
                <ul class="plan_box">
                    <li>
                        <div class="plan_title">What is the SiGREAL Talents Plan?</div>
                        <div class="plan_line"></div>
                        <div class="plan_text">The plan aims to recruit technology talents from TOP institutions around the world who are passionate about changing the world with advanced AI.</div>
                        <div class="plan_img"><img src="../../public/images/join/en/join_img1.jpg" /></div>
                    </li>
                    <li>
                        <div class="plan_title">How about our team?</div>
                        <div class="plan_line"></div>
                        <div class="plan_text">
                            SiGReal Tech was founded with a simple mission: Empower transportation industry with cutting-edge machine learning and AI techniques. Our team is committed to deliver on that mission. The passion, dedication, and expertise of our members have deeply shaped SiGReal Tech
                            and made it a great place to work.
                        </div>
                        <div class="plan_img"><img src="../../public/images/join/en/join_img2.jpg" /></div>
                    </li>
                    <li>
                        <div class="plan_title">What will you do when you join the plan?</div>
                        <div class="plan_line"></div>
                        <div class="plan_text">
                            If you are looking for fast-paced environments and using deep learning technology to solve real-world problems, this will be the perfect opportunity for you. <br />There are no specific job descriptions, as long as you are good and passionate, we can even tailor a
                            position for you.
                        </div>
                        <div class="plan_img"><img src="../../public/images/join/en/join_img3.jpg" /></div>
                    </li>
                    <li>
                        <div class="plan_title">Who are we looking for?</div>
                        <div class="plan_line"></div>
                        <div class="plan_text">If you have been studying in or graduated from a TOP 50 institutions in the world within two years, and meet any of the following criteria, you are welcome to apply:</div>
                        <ul class="plan_ul">
                            <li><span>BS (Bachelor of Science) in Computer Science, Mathematics, or a related quantitative field;</span></li>
                            <li><span>Published as the first author in top-tier machine learning conferences or journals, such as NeurIPS, ICML, ICLR, ICCV, CVPR, ACL, etc.</span></li>
                            <li><span>Participated in famous programming competitions, or other comparable experiences；</span></li>
                            <li><span>Internship experience in well-known startups or world-renowned large companies.</span></li>
                        </ul>
                        <div class="plan_btn" @click="dialogFormVisible = true">APPLY NOW</div>
                        <div class="plan_img"><img src="../../public/images/join/en/join_img4.jpg" /></div>
                    </li>
                </ul>
                <div class="contact_box">
                    <div class="contact_title">Other Positions</div>
                    <p>If you are passionate about innovation and technology, you are welcome to join us!</p>
                    <!-- <div class="contact_info">
                        <div><img src="../../public/images/join/en/phone_icon.png" /></div>
                        <span><a href="tel:010-87856177">010-87856177</a></span>
                    </div> -->
                    <div class="contact_info">
                        <div><img src="../../public/images/join/en/email_icon.png" /></div>
                        <span>hr@honglingtech.com.cn</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="section" v-else>
            <div class="banner">
                <p>关注我们</p>
                <!-- <div>和我们一起，做正确且难的事</div> -->
            </div>
            <!-- <div class="element_cn">
			<div class="element element_1"><img src="../../public/images/join/en/element_1.png" ></div>
			<div class="element element_2"><img src="../../public/images/join/en/element_2.png" ></div>
		</div> -->
            <div class="content">
                <div class="culture_box">
                    <p class="detail_title">企业文化</p>
                    <ul class="culture_list">
                        <li v-for="(item, index) in cultureData" :key="index">
                            <div><img :src="require('../../public/images/join/' + item.url)" /></div>
                            <p>{{ item.title }}</p>
                        </li>
                    </ul>
                </div>
                <p class="detail_title">加入我们</p>
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide swiper_slide1">
                            <p>社会招聘</p>
                            <div>SOCIAL RECRUITMENT</div>
                        </div>
                        <div class="swiper-slide swiper_slide2">
                            <p>瓴先人才计划</p>
                            <div>SiGREAL TALENTS PLAN</div>
                        </div>
                        <div class="swiper-slide swiper_slide3">
                            <p>校园招聘</p>
                            <div>CAMPUS RECRUITMENT</div>
                        </div>
                    </div>
                    <div class="swiper-button-prev btn swiper-button-black"></div>
                    <div class="swiper-button-next btn swiper-button-black"></div>
                </div>
                <div>
                    <ul class="post_box" v-show="checkedPostIndex == 0">
                        <li v-for="(item, index) in postData1" :key="index" @click="handlePost(item)">
                            <div class="post_name">
                                <span>{{ item.name }}</span>
                                <b>查看详情</b>
                            </div>
                            <div class="post_keyword">{{ item.keyword[0] }} | {{ item.keyword[1] }} | {{ item.keyword[2] }}</div>
                        </li>
                    </ul>
                    <ul class="post_box" v-show="checkedPostIndex == 1">
                        <li v-for="(item, index) in postData2" :key="index" @click="handlePost(item)">
                            <div class="post_name">
                                <span>{{ item.name }}</span>
                                <b>查看详情</b>
                            </div>
                            <div class="post_keyword">{{ item.keyword[0] }} | {{ item.keyword[1] }} | {{ item.keyword[2] }}</div>
                        </li>
                    </ul>
                    <ul class="post_box" v-show="checkedPostIndex == 2">
                        <li v-for="(item, index) in postData3" :key="index" @click="handlePost(item)">
                            <div class="post_name">
                                <span>{{ item.name }}</span>
                                <b>查看详情</b>
                            </div>
                            <div class="post_keyword">{{ item.keyword[0] }} | {{ item.keyword[1] }} | {{ item.keyword[2] }}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <contact-modal :dialogFormVisible="dialogFormVisible" :jobApplication="jobApplication" @closeModal="closeModal"></contact-modal>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import ContactModal from '@/components/ContactModal.vue';
import MyHeader from '@/components/MyHeader.vue';
import MyFooter from '@/components/MyFooter.vue';
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import { getScrollParent } from '@/assets/js/common.js';
var _this = '';
export default {
    name: 'JoinUs',
    components: {
        MyHeader,
        MyFooter,
        ContactModal
    },
    data() {
        return {
            swiper: null,
            formData: {
                username: '',
                phone: '',
                jobApplication: ''
            },
            jobApplication: '',
            dialogFormVisible: false,
            postData1: [
                { name: '大数据工程师', keyword: ['Spark', 'Java Hbase', 'Flink'] },
                { name: '机器学习算法科学家', keyword: ['PyTorch', '深度学习框架', 'NLP'] },
                { name: '商务总监', keyword: ['商务谈判', '商务拓展', '客户关系维护'] }
            ],
            postData2: [{ name: '瓴先人才计划', keyword: ['人工智能', '机器学习', '培养及储备人才'] }],
            postData3: [
                { name: '机器学习算法', keyword: ['PyTorch', 'Tensorflow', '研究深度模型'] },
                { name: '管培生', keyword: ['轮岗学习', '管理储备', '专属培养方案'] }
            ],
            cultureData: [
                {
                    id: 1,
                    url: 'culture_active1.png',
                    title: '成就客户',
                    details: ['以成就客户为己任', '站在客户立场考虑问题', '重视客户问题和需求', '尽心尽力，急客户之所急']
                },
                {
                    id: 2,
                    url: 'culture_active2.png',
                    title: '务实落地',
                    details: ['深入问题本质，从实际出发', '提出可量化指标和可行的解决方案', '以解决问题为最终目的', '精准执行，确保落地']
                },
                {
                    id: 3,
                    url: 'culture_active3.png',
                    title: '正直担当',
                    details: ['充满使命感，有大局观', '敢打头阵，勇当先锋', '有标准，有原则', '正视错误，及时纠正']
                },
                {
                    id: 4,
                    url: 'culture_active4.png',
                    title: '延迟满足感',
                    details: ['立足长远，打持久战', '愿为结果额外付出', '不设边界，持续迭代', '突破自我']
                },
                {
                    id: 5,
                    url: 'culture_active5.png',
                    title: '强执行力',
                    details: ['保持思考，不断提升，共同进步', '协同合作，及时复盘', '积极参与决策，认真执行落地', '理解执行方向，确保目标达成']
                },
                {
                    id: 6,
                    url: 'culture_active6.png',
                    title: '乐观进取',
                    details: ['传递正能量', '接受负面反馈，不断自我激励', '今天的最好表现是明天的最低要求', '热爱工作，迎难而上']
                }
            ],
            checkedPost: {
                name: '',
                duty: ['', '', ''],
                request: ['', '', '']
            },
            checkedItem: {},
            checkedPostIndex: 0,
            drawerVisible: false
        };
    },
    methods: {
        //点击加入我们照片墙的招聘块
        handleJoinWall(val) {
            this.checkedPostIndex = val == 1 || val == 4 ? 0 : val == 2 ? 1 : 2;
            // const element = getScrollParent(this.$el);
            // var footer=document.querySelector(".footer").offsetTop;
            // element.scrollTop=footer
        },
        closeModal() {
            this.dialogFormVisible = false;
        },
        //点击社会招聘或者校园招聘
        handleJoinType(sign) {
            this.$router.push({
                path: '/PostList',
                query: { joinType: sign }
            });
        },
        //点击申请该职位按钮
        handleApplyPost() {
            this.drawerVisible = false;
            this.dialogFormVisible = true;
        },
        onSubmit() {
            var _this = this;
            if (!_this.formData.username) {
                this.$notify({ title: '抱歉，请填写您的姓名', showClose: false, type: 'error' });
                return false;
            }
            if (!_this.formData.phone) {
                this.$notify({ title: '抱歉，请填写您的手机号', showClose: false, type: 'error' });
                return false;
            } else if (this.verificationPhone()) {
                this.$notify({ title: '抱歉，请填写正确的手机号', showClose: false, type: 'error' });
                return false;
            }
            this.instance
                .get('/addRecruitService', {
                    params: this.formData
                })
                .then((res) => {
                    this.dialogFormVisible = false;
                    this.formData = {
                        username: '',
                        phone: '',
                        jobApplication: ''
                    };
                    if (res.data.code == 1) {
                        this.$notify({
                            title: '成功',
                            message: res.data.msg,
                            type: 'success'
                        });
                    } else {
                        this.$notify({
                            title: '失败',
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                });
        },
        verificationPhone() {
            var _phone = this.formData.phone;
            var reg = /^1[3-9][0-9]\d{4,8}$/i; //验证手机正则(输入前7位至11位)
            if (_phone == '') {
                return true;
            } else if (_phone.length < 11) {
                return true;
            } else if (!reg.test(_phone)) {
                return true;
            } else {
                return false;
            }
        },
        handlePost(item) {
            this.$router.push({
                path: '/PostDetail',
                query: { postName: item.name }
            });
        }
    },
    mounted() {
        _this = this;
        this.swiper = new Swiper('.swiper-container', {
            //速度
            speed: 1000,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            //循环播放
            loop: true,
            //展示张数
            slidesPerView: 1,
            on: {
                slideChange: function () {
                    console.log(this.activeIndex);
                    _this.handleJoinWall(this.activeIndex);
                }
            }
        });
    }
};
</script>
<style scoped>
@import '../assets/css/public.css';
.swiper-container {
    width: 7.1rem;
    height: 3.1rem;
    margin-top: 0.8rem;
}
.swiper-slide {
    width: 100%;
    height: 3.1rem;
    background: url(../../public/images/join/join_1.png) no-repeat;
    background-size: 100% 100%;
    color: #fff;
    font-size: 0.2rem;
    text-align: center;
    padding-top: 1rem;
}
.swiper_slide2 {
    background: url(../../public/images/join/join_2.png) no-repeat;
    background-size: 100% 100%;
}
.swiper_slide3 {
    background: url(../../public/images/join/join_3.png) no-repeat;
    background-size: 100% 100%;
}
.swiper-slide p {
    font-size: 0.4rem;
    font-weight: 500;
    margin-bottom: 0.1rem;
}
.culture_box {
    width: 100%;
    height: 10.35rem;
    background: url(../../public/images/join/culture_bg.png) no-repeat;
    background-size: 100% 100%;
    padding-top: 0.5rem;
    margin-bottom: 0.9rem;
}
.culture_list {
    width: 7.2rem;
    margin: 0.62rem auto 0;
    display: flex;
    flex-wrap: wrap;
}
.culture_list li {
    width: 50%;
    text-align: center;
    font-weight: 500;
    color: #333333;
    font-size: 0.34rem;
    margin-bottom: 0.5rem;
}
.culture_list div {
    width: 1.7rem;
    margin: 0 auto;
}
.post_box {
    width: 100%;
    margin-top: 0.7rem;
}
.post_box li {
    background: url(../../public/images/join/post_bg.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 2.13rem;
    padding: 0.6rem 0.8rem;
}
.post_name {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.28rem;
    color: #333;
}
.post_name b {
    color: #05a3e4;
    font-size: 0.24rem;
}
.post_keyword {
    color: #999999;
    font-size: 0.24rem;
    margin-top: 0.2rem;
    vertical-align: center;
}
.join_wall {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.24rem;
    color: #666;
    margin-top: 0.7rem;
}
.join_wall li {
    width: 50%;
}
.join_wall_text {
    height: 2.9rem;
    background-color: #f4f3f3;
    padding: 0.5rem 0 0 0.3rem;
}
.join_wall_cn {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.34rem;
    color: #333333;
    margin-bottom: 0.2rem;
}
.join_wall_cn div {
    width: 0.32rem;
    margin-left: 0.2rem;
}
.join_wall_text.active {
    color: #fff;
    background-color: #0381b5;
}
.join_wall_text.active p {
    color: #fff;
}
.swiper-button-prev.swiper-button-black {
    background: url(../../public/images/join/last_icon.png) no-repeat;
    background-size: 100% 100%;
    left: 0.3rem;
}
.swiper-button-next.swiper-button-black {
    background: url(../../public/images/join/next_icon.png) no-repeat;
    background-size: 100% 100%;
    left: auto;
    right: 0.3rem;
}
.swiper-button-next,
.swiper-button-prev {
    width: 0.34rem;
    height: 0.34rem;
    top: 1.72rem;
}
.culture_intro {
    font-size: 0.28rem;
    margin: 0.5rem 0 0.7rem;
    line-height: 1.71;
}
.culture_text {
    padding: 0.4rem 0.78rem 1.1rem;
}
.culture_text p {
    font-size: 0.3rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
    /* padding-left: 0.1rem; */
    line-height: 1.41;
}
.culture_text li {
    font-size: 0.24rem;
    margin-bottom: 0.1rem;
    display: flex;
    /* align-items: center; */
    line-height: 1.67;
}
.culture_text li::before {
    display: block;
    content: '';
    width: 0.04rem;
    height: 0.04rem;
    background-color: #333;
    border-radius: 50%;
    margin-right: 0.1rem;
    margin-top: 0.18rem;
}
.culture_text li span {
    width: 5.3rem;
}
.contact_box p {
    line-height: 1.714;
    text-align: justify;
}
.contact_title {
    font-size: 0.46rem;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 1.4rem 0 0.8rem;
}
.contact_info {
    width: 100%;
    margin-top: 0.48rem;
    display: flex;
    align-items: center;
}
.contact_info div {
    width: 1.2rem;
    margin-right: 0.3rem;
}
.plan_btn {
    text-align: center;
    background: #05a3e4;
    border-radius: 0.3rem;
    color: #fff;
    font-size: 0.2rem;
    line-height: 1.4;
    padding: 0.13rem 0.4rem;
    display: inline-block;
}
.plan_ul {
    margin: 0.42rem 0 0.24rem;
}
.plan_ul li {
    display: flex;
    line-height: 1.714;
}
.plan_ul li::before {
    display: block;
    content: '';
    width: 0.14rem;
    height: 0.14rem;
    background: #05a3e4;
    border-radius: 50%;
    margin-right: 0.21rem;
    margin-top: 0.16rem;
}
.plan_ul li span {
    width: 6.76rem;
}
.section_en,
.section {
    position: relative;
    z-index: 1;
}
.content_en {
    padding: 0 0.2rem 0.5rem;
    font-size: 0.28rem;
    min-height: 49.6rem;
}
.plan_title {
    padding: 1.05rem 0 0.14rem;
    font-size: 0.46rem;
    font-weight: 500;
    color: #05a3e4;
    line-height: 1.413;
}
.plan_line {
    width: 0.8rem;
    height: 0.06rem;
    background: #05a3e4;
}
.plan_text {
    margin-top: 0.3rem;
    line-height: 1.714;
    text-align: justify;
}
.plan_img {
    margin-top: 0.45rem;
}
.element_en,
.element_cn {
    width: 100%;
    /* height: 49.6rem; */
    position: absolute;
    top: 4.4rem;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    /* overflow: visible; */
}
.element_cn {
    /* height: 34.85rem; */
}
.element_cn .element2 {
    width: 3.88rem;
    height: 4.07rem;
    top: 7.06rem;
    left: auto;
    right: 0;
}
.content {
    padding: 0 0 1.4rem;
    /* height: 34.85rem; */
    width: 100%;
}
.element {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 5rem;
    height: 3.8rem;
}
.element_2 {
    width: 3.88rem;
    height: 4.07rem;
    left: auto;
    right: 0;
    top: 7.46rem;
}
.element_3 {
    width: 4.09rem;
    height: 4.36rem;
    top: 18.9rem;
}
.element_4 {
    width: 4.46rem;
    height: 4.36rem;
    left: auto;
    right: 0;
    top: 29.2rem;
}
.banner,
.banner_en {
    width: 100%;
    height: 4.4rem;
    padding: 1.88rem 0.77rem 0;
    font-size: 0.24rem;
    font-weight: 400;
    background: url(../../public/images/join/banner.jpg) no-repeat;
    background-size: 100% 100%;
    line-height: 1.5;
    color: #fff;
}
.banner_en {
    background: url(../../public/images/join/en/banner.jpg) no-repeat;
    background-size: 100% 100%;
}
.banner p,
.banner_en p {
    font-size: 0.46rem;
    line-height: 1.39;
    margin-bottom: 0.2rem;
}
.detail_title {
    font-size: 0.42rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    text-align: center;
}
</style>
