<template>
    <div class="footer">
        <div class="section">
            <div class="footer_content">
                <div class="footer_content_left">
                    <p>{{ $t('Public.Footer.contactUs') }}</p>
                    <ul>
                        <li>
                            <div><img src="../../public/images/daohangdizhi.png" /></div>
                            <span>{{ $t('Public.Footer.addr') }}</span>
                        </li>
                        <li v-if="0">
                            <div><img src="../../public/images/dianhua.png" /></div>
                            <span>{{ $t('Public.Footer.tel') }}：<a href="tel:010-87856177">010-87856177</a></span>
                        </li>
                        <li>
                            <div><img src="../../public/images/youxiang.png" /></div>
                            <span>{{ $t('Public.Footer.email') }}</span>
                        </li>
                    </ul>
                </div>
                <div class="footer_content_right">
                    <p>{{ $t('Public.Footer.followUs') }}</p>
                    <div><img src="../../public/images/ercode_h.png" /></div>
                </div>
            </div>
            <div class="filing">
                <div>Copyright @2024 北京宏瓴科技发展有限公司</div>
                <div>
                    <span onclick="window.open('https://beian.miit.gov.cn')">{{ ICP }}</span>
                    京公网安备 11011302001560号
                </div>
            </div>
        </div>
        <!-- <div v-show="" class="back_top" @click="backTop"><img src="../../public/images/back_top.png" ></div> -->
    </div>
</template>

<script>
import { getScrollParent } from '@/assets/js/common.js';
export default {
    name: 'MyFooter',
    props: {
        msg: String
    },
    data() {
        return {
            activeIndex: '/',
            ICP: '京ICP备18036154号-1'
        };
    },
    methods: {},
    mounted() {
        var hostname = window.location.hostname;
        if (hostname == 'www.lvpaiche.com') {
            this.ICP = '京ICP备18036154号-2';
        } else if (hostname == 'www.honglingtech.com.cn') {
            this.ICP = '京ICP备18036154号-1';
        } else if (hostname == 'www.sigreal.cn') {
            this.ICP = '京ICP备18036154号-3';
        } else if (hostname == 'www.sigreal.com.cn') {
            this.ICP = '京ICP备18036154号-4';
        } else if (hostname == 'www.honglingtech.com') {
            this.ICP = '京ICP备18036154号-5';
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.back_top {
    width: 1.2rem;
    position: fixed;
    bottom: 1.5rem;
    right: 0.1rem;
    z-index: 9999;
}
.footer {
    width: 100%;
    min-height: 4.3rem;
    background: #222222;
    padding: 0.59rem 0 0.28rem;
}
.section {
    width: 6.7rem;
    margin: 0 auto;
}
.footer_content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.56rem;
    border-bottom: 1px solid #666;
}
.footer_content_left {
    width: 4.9rem;
}
.footer_content_right {
    width: 1.8rem;
}
.footer_content_left p,
.footer_content_right p {
    font-size: 0.28rem;
    color: #666;
}
.footer_content_left a {
    color: #666;
}
.footer_content_right p {
    text-align: center;
}
.filing {
    text-align: center;
    color: #666;
    margin-top: 0.19rem;
    font-size: 0.2rem;
    line-height: 1.5;
}
.filing span {
    cursor: pointer;
}
.footer_content_left ul {
    margin-top: 0.3rem;
}
.footer_content_left li {
    font-size: 0.24rem;
    color: #666;
    /* line-height: 1.6; */
    display: flex;
    align-items: center;
    margin-bottom: 0.16rem;
}
.footer_content_left li div {
    width: 0.34rem;
    margin-right: 0.15rem;
}
.footer_content_left li span {
    width: 4.46rem;
}
.footer_content_right div {
    width: 1.4rem;
    margin: 0.2rem auto 0;
}
</style>
